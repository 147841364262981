<template>
  <!-- Save dialog -->
  <v-dialog height="200" width="400" v-model="value">
    <v-card>
      <v-card-title>Publish New Workflow Version</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="version.displayName"
          label="New workflow version name"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-btn @click="close">Close</v-btn>
        <v-btn @click="saveVersion" :disabled="!version.displayName">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CREATE_WORKFLOW_VERSION from '../graphql/Mutation/CreateWorkflowVersion.gql';

const defaultVersion = () => {
  return {
    displayName: null,
    liteGraph: null,
  };
};

export default {
  name: 'SaveDialog',
  data() {
    return {
      version: defaultVersion(),
    };
  },
  props: {
    value: Boolean,
  },
  methods: {
    close() {
      this.$store.commit('toggleDialog', { dialog: 'saveDialog', open: false });
    },
    async saveVersion() {
      this.close();
      const { providerId } = this.$store.state.user;
      this.version.liteGraph = JSON.stringify(this.$graph.serialize());
      const { version } = this;
      const workflowId = this.$store.state.activeWorkflow.id;
      try {
        const result = await this.$apollo.mutate({
          mutation: CREATE_WORKFLOW_VERSION,
          variables: {
            providerId,
            workflowId,
            version,
          },
        });
        const { createWorkflowVersion: activeWorkflowVersion } = result?.data;
        if (activeWorkflowVersion) {
          this.$store.commit('setActiveWorkflowVersion', {
            ...activeWorkflowVersion,
            parent: workflowId,
          });
        }
        this.$store.commit('set', { refreshWorkflowVersions: true });
        this.$store.dispatch('setNotification', {
          color: 'success',
          timeout: 15000,
          text: `Workflow version "${this.version.displayName}" saved`,
        });
      } catch (e) {
        this.$store.dispatch('setNotification', {
          color: 'error',
          timeout: 15000,
          text: `Error saving version: ${e.message}`,
        });
      }
    },
  },
  watch: {
    value(val) {
      if (!val) return;
      this.version = defaultVersion();
    },
  },
};
</script>
